<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="snackbarColor">
      <span>{{ snackbarMessage }}</span>
      <v-btn text color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-app-bar app text class="custom-app-bar">
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        class="text-uppercase grey--text darken-2 pointer"
        @click="routeTo(dashboardLink, null)"
      >
        <span>Marianas</span>
        <span class="font-weight-light"> Logistics</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          v-for="subLink in subLinks"
          :key="subLink.route"
          router
          :to="subLink.route"
          >{{ subLink.text }}</v-btn
        >
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-menu bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn small fab icon class="blue-grey lighten-5" v-on="on">
            <v-avatar size="42">
              <img
                :src="userInfo.profileImage"
                alt
                v-if="userInfo.profileImage"
              />
              <span v-else class="title pa-2 blue--text font-weight-bold">{{
                getFirstLetterOfUserName()
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item key="ac-1" @click="editProfile()">
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signout()" key="ac-2">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      :clipped="false"
      v-model="drawer"
      app
      dark
      class="gray darken-4"
      enable-resize-watcher
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="'px-0'">
          <v-list-item-avatar color="white" class="my-0">
            <img
              :src="userInfo.profileImage"
              alt
              v-if="userInfo.profileImage"
            />
            <span v-else class="display-1 pa-2 blue--text font-weight-bold">{{
              getFirstLetterOfUserName()
            }}</span>
          </v-list-item-avatar>

          <v-list-item-content class="white--text">
            <v-list-item-title class="font-weight-bold subtitle-1">{{
              userInfo.name
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="white--text pointer underline"
              @click="editProfile()"
              >Manage Account</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- Admin left side nav links --->
      <v-list v-if="userInfo.type == 1">
        <v-list-item
          v-for="(link, index) in adminLeftNav"
          :key="index"
          @click="routeTo(link.route, 0)"
          active-class="highlighted"
          :class="link.index === currentProjectId ? 'highlighted' : ''"
        >
          <v-list-item-avatar>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- end manager left side nav link -->
    </v-navigation-drawer>
  </nav>
</template>

<script>
//import axios from "axios";
import EventBus from "@/eventBus";
import Pusher from "pusher-js";
import CustomLog from "@/plugins/customlog";
//const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const PUSHER_KEY = process.env.VUE_APP_PUSHER_API_KEY;
const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_API_CLUSTER;
const PUSHER_NOTIFICATION_CHNL = process.env.VUE_APP_PUSHER_NOTIFICATION_CHNL;
const PUSHER_NOTIFICATION_EVENT = process.env.VUE_APP_PUSHER_NOTIFICATION_EVENT;
export default {
  data() {
    return {
      drawer: true,
      subLinks: [],
      userInfo: {},
      projects: [],
      adminLeftNav: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "white",
      hasNotificaitons: true,
      notificatonCount: 0,
      notificationList: [],
      currentProjectId: 0,
      dashboardLink: "",
      pusherMessage: null,
      userProfile: {
        name: "none",
        profileImage: "",
      },
    };
  },
  components: {}, //AddProject, AddProjectMngr
  computed: {},
  created() {
    CustomLog.log(PUSHER_KEY);

    if (this.$store.state.loggedinInfo != null) {
      this.userInfo = this.$store.state.loggedinInfo;
      CustomLog.log("ProfileImage", this.userInfo.profileImage);

      if (this.userInfo.type == 3) {
        this.loadList(1, "getAll");
        this.dashboardLink = "maintenance";
      } else if (this.userInfo.type == 1) {
        this.dashboardLink = "maintenance";
      } else if (this.userInfo.type == 2) {
        this.dashboardLink = "maintenance";
      }

      this.adminLeftNav = [
        {
          icon: "mdi-view-dashboard-outline",
          text: "Report",
          route: "report",
        },
        {
          icon: "mdi-clipboard-text-outline",
          text: "Report History",
          route: "report-history",
        },
        {
          icon: "mdi-wrench-clock",
          text: "Maintenance",
          route: "maintenance",
        },
        {
          icon: "mdi-truck-fast",
          text: "Trucks",
          route: "truck",
        },
        {
          icon: "mdi-truck-flatbed",
          text: "Trailer",
          route: "trailer",
        },
        { icon: "mdi-account-circle", text: "Drivers", route: "driver" },
      ];

      // this.loadNotifications();
    }
    //this.initPuserApi();
  },
  mounted() {
    if (this.$store.state.loggedinInfo != null) {
      this.userInfo = this.$store.state.loggedinInfo;
      this.profileImage = this.userInfo.profileImage;
    }
    EventBus.$on("display-message", (data) => {
      this.displayMessage(data.message, data.isError);
    });
    EventBus.$on("new-notification", (message) => {
      CustomLog.log(message);
      //  this.loadNotifications();
    });

    EventBus.$on("pofileUpdated", (userinfo) => {
      CustomLog.log("useInfo:" + JSON.stringify(userinfo));
      this.userInfo = userinfo;
      this.profileImage = this.userInfo.image;
      CustomLog.log("useInfo:" + this.userInfo.profileImage);
    });
  },
  methods: {
    getFirstLetterOfUserName() {
      if (this.userInfo.id != null) {
        return this.userInfo.name.substring(0, 1);
      }
    },
    getTodayDate() {
      return new Date().toUTCString().slice(5, 16);
    },
    isClientOrManager() {
      if (this.userInfo.type == 3 || this.userInfo.type == 1) {
        return true;
      } else {
        return false;
      }
    },
    routeTo(route, param) {
      this.currentProjectId = param;
      /* this.subLinks = [
        { text: "Dashboard", route: "/client/dashboard" },
        { text: "Bugs", route: "/musicAds" },
        { text: "Discussion", route: "/radioAds" },
        { text: "Attachments", route: "/newsAds" }
      ];*/
       CustomLog.log(route + "/ " +this.$route.name);
      if (this.$route.name !== route) {
        this.$router
          .replace({ name: route, params: { id: param } })
          .catch((err) => {
            CustomLog.log(err);
          });
      }
    },
    onClickNotification(routePath, notificationId) {
      console.log(routePath, notificationId);
    },
    displayMessage(message, isError) {
      if (isError) {
        this.snackbarColor = "red";
      } else {
        this.snackbarColor = "success";
      }
      this.snackbar = true;
      this.snackbarMessage = message;
    },
    signout() {
      EventBus.$emit("authenticated", false);
    },

    editProfile() {
      this.routeTo("profile", null);
    },
    loadNotifications() {},
    notifyData(message) {
      /*  if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      Notification.requestPermission().then(function(result) {
        if (result === "granted") {
          let notification = new Notification(message);
          CustomLog.log(notification);

        }
      });*/

      CustomLog.log(message);
    },
    initPuserApi() {
      CustomLog.log("PUSHER_API: ", PUSHER_KEY);

      const vm = this;
      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true;
      var pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
      var channel = pusher.subscribe(PUSHER_NOTIFICATION_CHNL);
      channel.bind(PUSHER_NOTIFICATION_EVENT, function (data) {
        vm.pusherMessage = data;
        //const routeName = vm.$router.currentRoute.name;
        CustomLog.log(data);
        if (vm.pusherMessage.notification.for == vm.userInfo.type) {
          if (vm.pusherMessage.notification.assignTo == vm.userInfo.id) {
            // vm.loadNotifications();
            //vm.notificatonCount++;
            if (vm.pusherMessage.notification.type == "task") {
              if (vm.pusherMessage.notification.for == "1") {
                if (vm.pusherMessage.notification.action == "update") {
                  // for manager
                  EventBus.$emit("task-update", true);
                }
              } else if (vm.pusherMessage.notification.for == "2") {
                // for team
                if (vm.pusherMessage.notification.action == "new") {
                  EventBus.$emit("new-task", true);
                } else {
                  EventBus.$emit("task-update", true);
                }
              }
              //  vm.loadNotifications();
              /*,
                vm.pusherMessage.notification.type,
                vm.pusherMessage.notification.action*/
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            } else if (vm.pusherMessage.notification.type == "bug") {
              EventBus.$emit("new-bug", true);

              if (vm.pusherMessage.notification.for == "1") {
                if (vm.pusherMessage.notification.action == "update") {
                  // for manager
                  EventBus.$emit("bug-update", true);
                }
              } else if (vm.pusherMessage.notification.for == "2") {
                // for team
                if (vm.pusherMessage.notification.action == "new") {
                  EventBus.$emit("new-bug", true);
                } else {
                  EventBus.$emit("bug-update", true);
                }
              }
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            } else if (vm.pusherMessage.notification.type == "project") {
              EventBus.$emit("project-added", true);
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            }
          }
          // vm.notifyData(vm.pusherMessage);
        }
        CustomLog.log(vm.pusherMessage);
      });
    },
    showNotification(title, message, profileImage) {
      const notification = new Notification(title, {
        body: message,
        icon: profileImage,
      });
      CustomLog.log(notification);
    },
  },
};
</script>
<style scoped>
.v-toolbar__extension {
  height: 5px !important;
}
.highlighted {
  background: black;
}
.underline {
  text-decoration: underline;
}
.custom-app-bar {
  background: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  max-height: 60px;
}

.pointer {
  cursor: pointer;
}
</style>
