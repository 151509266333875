<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        color="primary"
        small
        class="ml-3"
        outlined
        v-on="on"
        @click="showDialog"
        >Add New Driver</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <h2 class="title">{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="First Name*"
                  v-model="firstName"
                  :rules="inputRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="Last Name*"
                  v-model="lastName"
                  required
                  :rules="inputRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  label="Driver Id*"
                  v-model="driverId"
                  required
                  :rules="inputRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="Email*"
                  v-model="email"
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field label="Phone" v-model="phone"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Issue State/Province"
                  v-model="issueProvince"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Number"
                  v-model="licenseNumber"
                ></v-text-field>
              </v-col>
              <!-- License Expery Date-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="licenseExperyDateMenu"
                  v-model="licenseExperyDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="licenseExperyDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="licenseExperyDate"
                      label="License Experies Date*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="licenseExperyDate"
                    no-title
                    scrollable
                    @input="$refs.licenseExperyDateMenu.save(licenseExperyDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="licenseExperyDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.licenseExperyDateMenu.save(licenseExperyDate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--End license Expery Date-->
              <v-col cols="12" md="12" class="py-0" v-if="action == 'update'">
                <v-select
                  :items="statusList"
                  v-model="status"
                  item-text="title"
                  item-value="id"
                  label="Status*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >

                <v-btn
                  class="ml-3 mx-0 mt-3"
                  color="error"
                  outlined
                  v-show="isVisibleDeleteBtn"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteItem()"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
import { format } from "date-fns";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      isVisibleDeleteBtn: false,
      driverId: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      issueProvince: "",
      licenseNumber: "",
      userInfo: null,
      title: "Add New Driver",
      btnLabel: "Add Driver",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      phoneRules: [
        (v) => (v && v.length >= 10) || "Minimum length is 10 characters",
      ],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
      ],
      loading: false,
      dialog: false,
      action: "get",
      fuelList: [],
      fuelType: {},
      licenseExperyDate: "",
      licenseExperyDateMenu: false,
      statusList: [
        { id: "1", title: "Enable" },
        { id: "2", title: "Disable" },
      ],
      status: { id: "1", title: "Enable" },
    };
  },
  methods: {
    getFormatedDate(date) {
      if (date == "") {
        return date;
      } else {
        return format(new Date(date), "yyyy-MM-dd");
      }
    },
    deleteItem() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("id", this.id);
      formData.append("action", "delete");
      axios
        .post(BASE_URL + "driver.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            EventBus.$emit("driver-update", vm.error);
            vm.dialog = false;
            vm.$refs.form.reset();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        var formData = new FormData();
        formData.append("adminId", vm.userInfo.id);
        formData.append("driverId", vm.driverId);
        formData.append("firstName", vm.firstName);
        formData.append("lastName", vm.lastName);
        formData.append("issueProvince", vm.issueProvince);
        formData.append("licenseNumber", vm.licenseNumber);
        formData.append("licenseExperyDate", vm.licenseExperyDate);
        formData.append("phone", vm.phone);
        formData.append("email", vm.email);
        formData.append("action", vm.action);
        if (vm.action == "update") {
          formData.append("id", vm.id);
          formData.append("status", vm.status.id);
        }
        axios
          .post(BASE_URL + "driver.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {'message':vm.message,"isError":response.data.error}
            EventBus.$emit("display-message", messageObject);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("driver-update", vm.error);
              } else {
                EventBus.$emit("driver-added", vm.error);
              }
              vm.dialog = false;          
            vm.$refs.form.reset();
            }
            
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
            let messageObject = {'message':"Some error occured!","isError":true}
            EventBus.$emit("display-message", messageObject);
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.btnLabel = "Add Driver";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Driver";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      CustomLog.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadDefaultData(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      axios
        .post(BASE_URL + "setting.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.fuelList = response.data.list;
            if (vm.fuelList.length > 0) {
              vm.fuelType = response.data.list[0];
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    showDeleteBtn(e) {
      if (e.altKey && e.shiftKey && e.code === "KeyD") {
        if (this.action == "update") {
          this.isVisibleDeleteBtn = true;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-driver", (data) => {
      this.driverId = data.refId;
      this.licenseNumber = data.licenseNumber;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.phone = data.phone;
      this.email = data.email;
      this.issueProvince = data.issueProvince;
      this.licenseExperyDate = this.getFormatedDate(data.licenseExperyDate);
      this.id = data.id;
      this.btnLabel = "Update Driver";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Driver";
      this.status = data.status;
      this.isVisibleDeleteBtn = false;
    });
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    window.addEventListener("keydown", this.showDeleteBtn);
    // this.loadDefaultData("get");
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
