<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        color="primary"
        small
        class="ml-3"
        outlined
        v-on="on"
        @click="showDialog"
        >Add New Trailer</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <h2 class="title">{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Trailer Id*"
                  v-model="truckId"
                  :rules="inputRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="VIN*"
                  v-model="vin"
                  required
                  :rules="inputRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="fuelList"
                  v-model="fuelType"
                  item-text="title"
                  item-value="id"
                  label="Fuel Type*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Issue State/Province"
                  v-model="issueProvince"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Plate Number"
                  v-model="plateNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Year" v-model="year"></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Make" v-model="make"></v-text-field>
              </v-col>

              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Model" v-model="model"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  :items="companyList"
                  v-model="company"
                  item-text="title"
                  item-value="id"
                  label="Under Taking Company*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="anualSaftyDateMenu"
                  v-model="anualSaftyDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="anualSaftyDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="anualSaftyDate"
                      label="Annual Safety*"
                      readonly
                      required
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="anualSaftyDate"
                    no-title
                    scrollable
                    @input="$refs.anualSaftyDateMenu.save(anualSaftyDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="anualSaftyDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.anualSaftyDateMenu.save(anualSaftyDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- PM-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="pmDateMenu"
                  v-model="pmDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="pmDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="pmDate"
                      label="PM*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pmDate"
                    no-title
                    scrollable
                    @input="$refs.pmDateMenu.save(pmDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="pmDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.pmDateMenu.save(pmDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <!-- end PM -->
              <!-- Insurance Slip Date-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="insuranceSlipDateMenu"
                  v-model="insuranceSlipDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="insuranceSlipDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="insuranceSlipDate"
                      label="Insurance Slip*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="insuranceSlipDate"
                    no-title
                    scrollable
                    @input="$refs.insuranceSlipDateMenu.save(insuranceSlipDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="insuranceSlipDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.insuranceSlipDateMenu.save(insuranceSlipDate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--End Insurance Slip Date-->
              <v-col cols="12" md="12" class="py-0" v-if="action == 'update'">
                <v-select
                  :items="statusList"
                  v-model="status"
                  item-text="title"
                  item-value="id"
                  label="Status*"
                  return-object
                ></v-select>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >
                <v-btn
                  class="ml-3 mx-0 mt-3"
                  color="error"
                  outlined
                  v-show="isVisibleDeleteBtn"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteItem()"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
import { format } from "date-fns";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      isVisibleDeleteBtn: false,
      truckId: "",
      vin: "",
      issueProvince: "",
      plateNumber: "",
      year: "",
      make: "",
      model: "",
      userInfo: null,
      title: "Add New Trailer",
      btnLabel: "Add Trailer",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
      fuelList: [],
      fuelType: {},
      insuranceSlipDateMenu: false,
      insuranceSlipDate: "",
      companyList: [
        { id: "1", title: "Marianas Transportation" },
        { id: "2", title: "Marianas Logistics" },
      ],
      company: { id: "1", title: "Marianas Transportation" },
      anualSaftyDate: "",
      anualSaftyDateMenu: false,
      pmDate: "",
      pmDateMenu: false,
      statusList: [
        { id: "1", title: "Enable" },
        { id: "2", title: "Disable" },
      ],
      status: { id: "1", title: "Enable" },
    };
  },
  methods: {
    getFormatedDate(date) {
      if (date == "") {
        return date;
      } else {
        return format(new Date(date), "yyyy-MM-dd");
      }
    },
    deleteItem() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("id", this.id);
      formData.append("action", "delete");
      axios
        .post(BASE_URL + "trailer.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            EventBus.$emit("trailer-update", vm.error);
            vm.dialog = false;
            vm.$refs.form.reset();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        var formData = new FormData();
        formData.append("adminId", vm.userInfo.id);
        formData.append("truckId", vm.truckId);
        formData.append("vin", vm.vin);
        formData.append("issueProvince", vm.issueProvince);
        formData.append("plateNumber", vm.plateNumber);
        formData.append("year", vm.year);
        formData.append("make", vm.make);
        formData.append("model", vm.model);
        formData.append("fuelId", vm.fuelType.id);
        formData.append("companyId", vm.company.id);
        formData.append("annualSafetyDate", vm.anualSaftyDate);
        formData.append("pmDate", vm.pmDate);
        formData.append("insuranceSlipDate", vm.insuranceSlipDate);
        formData.append("action", vm.action);
        if (vm.action == "update") {
          formData.append("id", vm.id);
          formData.append("status", vm.status.id);
        }
        axios
          .post(BASE_URL + "trailer.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {
              message: vm.message,
              isError: response.data.error,
            };
            EventBus.$emit("display-message", messageObject);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("trailer-update", vm.error);
              } else {
                EventBus.$emit("trailer-added", vm.error);
              }
              vm.dialog = false;
              vm.$refs.form.reset();
            }

            // vm.name = "";
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
            let messageObject = {
              message: "Some error occured!",
              isError: true,
            };
            EventBus.$emit("display-message", messageObject);
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.btnLabel = "Add Trailer";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Trailer";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      CustomLog.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadDefaultData(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      axios
        .post(BASE_URL + "setting.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.fuelList = response.data.list;
            if (vm.fuelList.length > 0) {
              vm.fuelType = response.data.list[0];
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    showDeleteBtn(e) {
      if (e.altKey && e.shiftKey && e.code === "KeyD") {
        if (this.action == "update") {
          this.isVisibleDeleteBtn = true;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-trailer", (data) => {
      CustomLog.log(data.status);
      this.truckId = data.refId;
      this.plateNumber = data.plateNumber;
      this.vin = data.vin;
      this.year = data.year;
      this.make = data.make;
      this.model = data.model;
      this.issueProvince = data.issueProvince;
      this.fuelType = data.fuelType;
      this.company = data.company;
      this.anualSaftyDate = this.getFormatedDate(data.annualSafetyDate);
      this.pmDate = this.getFormatedDate(data.pmDate);
      this.insuranceSlipDate = this.getFormatedDate(data.insuranceSlipDate);
      this.id = data.id;
      this.btnLabel = "Update Trailer";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Trailer";
      this.status = data.status;
      this.isVisibleDeleteBtn = false;
    });
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.loadDefaultData("get");
    window.addEventListener("keydown", this.showDeleteBtn);
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
