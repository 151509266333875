<template>
  <div>
    <AddLoader :dialog="loading" />
    <v-container fluid>
      <v-row v-if="items.length == 0">
        <v-col cols="12 py-3">
          <div class="body">{{ resultMsg }}</div>
        </v-col>
      </v-row>
      <v-card tile>
        <v-card-title>
          Maintenance <AddMaintenance />
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            small
            class="mr-3"
            outlined
            @click="reloadAll()"
            >Show All</v-btn
          >

          <v-menu
            ref="dateFilterMenu"
            v-model="dateFilterMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="dateFilter"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="ml-3 mr-4 max-width"
                v-model="dateFilterTextField"
                label="Date Filter"
                dense
                v-on="on"
                clearable
                @click:clear="reloadAll()"
                v-on:click:append="dateFilterMenu = true"
                append-icon="mdi-calendar-check-outline"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFilter"
              no-title
              scrollable
              multiple
              range
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateFilterMenu = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="filterByDate()">OK</v-btn>
            </v-date-picker>
          </v-menu>

          <v-text-field
            v-model="search"
            class="search-field"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="ml-3"
            outlined
            @click="exportData()"
            >Export <v-icon small>mdi-microsoft-excel</v-icon></v-btn
          >
          <AddMaintenanceType />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          fixed-header
          hide-default-footer
          height="74vh"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          @current-items="currentItems"
          show-expand
          class="maintenance-table"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-none">{{ item }}</div>
            <v-btn
              color="primary"
              small
              tile
              elevation="0"
              @click="update(item)"
              >Update</v-btn
            >
          </template>

          <!-- for expend -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">{{ item.detail }}</td>
          </template>
          <!-- end for expend-->
        </v-data-table>
      </v-card>
      <div class="text-right my-3 mr-5">
        <p class="font-weight-bold">Grand Total: {{ grandTotal }}</p>
      </div>
    </v-container>
  </div>
</template>
    <script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddMaintenance from "@/components/AddMaintenance.vue";
import AddMaintenanceType from "@/components/AddMaintenanceType.vue";
import AddLoader from "@/components/AddLoader";
import { format, parseISO } from "date-fns";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      loading: false,
      message: "",
      resultMsg: "",
      items: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],

      searchText: "", // new data start
      search: "",
      headers: [
        {
          text: "Vehicle Id",
          align: "start",
          sortable: false,
          value: "itemInfo.refId",
          divider: true,
          width: 80,
        },
        {
          text: "Vehicle Type",
          align: "start",
          sortable: true,
          value: "for.title",
          divider: true,
          width: 80,
        },
        { text: "VIN", value: "itemInfo.vin", divider: true, width: 150 },
        {
          text: "Fuel Type",
          value: "itemInfo.fuelType.title",
          divider: true,
          width: 80,
        },
        {
          text: "License Issue Province",
          value: "itemInfo.issueProvince",
          divider: true,
          width: 100,
        },
        {
          text: "License Plate Number",
          value: "itemInfo.plateNumber",
          divider: true,
          width: 100,
        },
        { text: "Year", value: "itemInfo.year", divider: true, width: 80 },
        { text: "Make", value: "itemInfo.make", divider: true, width: 100 },
        { text: "Model", value: "itemInfo.model", divider: true, width: 80 },
        {
          text: "Under Taking Company",
          value: "itemInfo.company.title",
          divider: true,
          width: 150,
        },
        {
          text: "Maintenance Type",
          value: "type.title",
          divider: true,
          width: 110,
        },
        { text: "Cost ($)", value: "cost", divider: true, width: 110 },
        {
          text: "Date",
          value: "date",
          divider: true,
          width: 110,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          width: 100,
        },
      ],
      expanded: [],
      singleExpand: true,
      dateFilter: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      dateFilterTextField: [],
      dateFilterMenu: false,
      bottom: false,
      grandTotal: 0.0,
    };
  },
  computed: {},
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.dateFilterTextField = this.getFormatedDateFilter();
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("maintenance-added", (error) => {
      CustomLog.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("maintenance-update", (error) => {
      CustomLog.log(error);
      this.loadList(this.page, "get");
    });
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        if (this.items.length < this.totalItems) {
          CustomLog.log("Next Page Data");
          this.loadList(this.page, this.action);
        }
      }
    },
  },
  methods: {
    getFormatedDateFilter() {
      return [
        format(parseISO(this.dateFilter[0]), "d MMM yyyy"),
        format(parseISO(this.dateFilter[1]), "d MMM yyyy"),
      ].join(" - ");
    },
    currentItems: function (array) {
      CustomLog.log(array);
      this.grandTotal = 0.0;
      array.forEach((item) => {
        CustomLog.log(item.cost);
        this.grandTotal += parseFloat(item.cost);
      });
      this.grandTotal = parseFloat(this.grandTotal).toFixed(2);
    },
    reloadAll() {
      this.dateFilter = [
        format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      ];
      this.dateFilterTextField = this.getFormatedDateFilter();
      this.page = 1;
      this.loadList(this.page, "get");
    },
    exportData() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "maintenance");
      axios
        .post(BASE_URL + "export.php", formData, {
          responseType: "arraybuffer",
        })
        .then(function (response) {
          vm.loading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Maintenance-${new Date().toLocaleDateString()}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    addTableScrollListener() {
      document
        .querySelector(".v-data-table__wrapper")
        .addEventListener("scroll", () => {
          this.bottom = this.bottomVisible();
        });
    },
    bottomVisible() {
      const scrollY = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollTop;
      const tableHeight = document.querySelector(
        ".v-data-table__wrapper table"
      ).clientHeight;
      const visible = document.querySelector(
        ".v-data-table__wrapper "
      ).clientHeight;
      const pageHeight = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollHeight;
      if (tableHeight >= visible) {
        const bottomOfPage = visible + scrollY >= pageHeight;
        return bottomOfPage || pageHeight < visible;
      } else {
        return false;
      }
    },
    filterByDate() {
      CustomLog.log(
        "start Date:" + this.dateFilter[0] + ", End Date: " + this.dateFilter[1]
      );
      this.dateFilterTextField = this.getFormatedDateFilter();
      this.$refs.dateFilterMenu.save(this.dateFilter);
      this.isLoadingTrackingCode = false;
      const vm = this;
      this.action = "searchByDate";
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      let startDate = this.dateFilter[0];
      let endDate = this.dateFilter[1];
      if (startDate > endDate) {
        startDate = this.dateFilter[1];
        endDate = this.dateFilter[0];
      }

      formData.append("adminId", this.userInfo.id);
      formData.append("startDate", startDate);
      formData.append("action", this.action);
      if (this.dateFilter[1] != undefined) {
        formData.append("endDate", endDate);
      }
      axios
        .post(BASE_URL + "maintenanceReport.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
            vm.items = [];
          } else {
            vm.items = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(date, "Do MMM YYYY");
      }
    },
    loadList(page, action) {
      const vm = this;
      this.loading = true;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      CustomLog.log(this.userInfo.fname);
      axios
        .post(BASE_URL + "maintenanceReport.php?page=" + page, formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          //Add Scroll Listener
          if (vm.items.length <= 0) {
            vm.addTableScrollListener();
          } //end
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            if (response.data.page <= 1) {
              vm.items = response.data.list;
              vm.totalItems = parseInt(response.data.totalItems);
            } else {
              vm.items.push(...response.data.list);
            }
            vm.page = vm.page + 1;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    getPageData(page) {
      CustomLog.log(page);
      this.loadList(page, this.action);
    },
    update(data) {
      CustomLog.log(data.id);
      EventBus.$emit("edit-maintenance", data);
    },
    searchQuery(page) {
      CustomLog.log(this.searchText.length);
      if (this.searchText.length > 2) {
        this.loading = true;
        const vm = this;
        var formData = new FormData();
        formData.append("keyword", vm.searchText);
        formData.append("adminId", vm.userInfo.id);
        formData.append("action", "search");
        axios
          .post(BASE_URL + "truck.php?page=" + page, formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            if (response.data.error) {
              vm.message = response.data.message;
              if (page == 1) {
                vm.resultMsg = "No result found";
                vm.items = [];
              }
            } else {
              vm.items = response.data.list;
            }
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
          });
      } else if (this.searchText.length == 0) {
        this.page = 1;
        this.loadList(this.page, this.action);
      }
    },
  },
  components: { AddMaintenance, AddMaintenanceType, AddLoader },
};
</script>
<style>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: lightgray !important;
}
.max-width {
  max-width: 229px;
  padding-top: 0px !important;
  margin-top: 25px !important ;
}
.search-field {
  padding-top: 0px !important;
  margin-top: 0px !important ;
}
</style>