<template>
  <v-app class="custom-bg">
    <v-snackbar v-model="updateExists" top color="warning">
      <span> New version available! Click to update</span>
      <v-btn text color="white" @click="refreshApp">Refresh</v-btn>
    </v-snackbar>
    <Navbar v-if="authenticated" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import EventBus from "@/eventBus";
import CustomLog from "@/plugins/customlog";
export default {
  name: "App",

  data: () => ({
    authenticated: false,
    updateExists: false,
  }),
  components: {
    Navbar,
  },
  mounted() {
    this.checkLogin();
    // check current route name
    const routePath = this.$router.currentRoute.path;
    //end
    if (!this.authenticated) {
      if (!this.checkLogin()) {
        this.$router
          .replace({
            name: "login",
            query: { redirect: routePath },
          })
          .catch((err) => {
            CustomLog.log(err);
          });
      }
    } else {
      // if auth success
      /* this.$router.replace({ name: "maintenance" }).catch((err) => {
        CustomLog.log(err);
      });*/
    }
    this.$router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!this.checkLogin()) {
          next({
            name: "login",
            query: { redirect: to.fullPath },
          }).catch((err) => {
            CustomLog.log(err);
          });
        } else {
          next();
        }
      } else {
        next(); // make sure to always call next()!
      }
    });
  },
  created() {
    EventBus.$on("authenticated", (status) => {
      this.authenticated = status;
      CustomLog.log("Is Authticated 2: ", status);
      //reset login session data
      if (!status) {
        this.$store.dispatch("saveloggedinInfo", null);
      }

      if (this.$store.state.loggedinInfo != null) {
        if (this.$store.state.loggedinInfo.id > 0) {
          this.$router.replace({ name: "maintenance" }).catch((err) => {
            CustomLog.log(err);
          });
        }
      } else {
        this.$router.replace({ name: "login" }).catch((err) => {
          CustomLog.log(err);
        });
      }
    });
  },
  methods: {   
    refreshApp() {
      CustomLog.log("refresh App");
    },
    checkLogin() {
      if (this.$store.state.loggedinInfo != null) {
        if (this.$store.state.loggedinInfo.id > 0) {
          this.authenticated = true;
        } else {
          this.authenticated = false;
        }
      }
      return this.authenticated;
    },
  },
};
</script>

<style >

.active-row {
  color: #222;
}
.inactive-row {
  color: #b1b1b1;
}

.custom-bg {
  background-color: #f5f5f5 !important;
}
</style>