<template>
  <div>
    <AddLoader :dialog="loading" />
    <v-container fluid>
      <v-card tile>
        <v-card-title>
          Manage Drivers <AddDriver />
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            class="search-field"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="ml-3"
            outlined
            @click="exportData()"
            >Export <v-icon small>mdi-microsoft-excel</v-icon></v-btn
          >
        </v-card-title>
        <v-data-table
          id="virtual-scroll-table"
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          fixed-header
          hide-default-footer
          :item-class="rowClass"
          height="80vh"
        >
          <template v-slot:item.action="{ item }">
            <v-btn
              color="primary"
              small
              tile
              elevation="0"
              @click="update(item)"
              >Update</v-btn
            >
          </template>

          <template v-slot:item.licenseExperyDate="{ item }">
            <div
              :class="`${
                item.highlightExperyDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.licenseExperyDate }}
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddDriver from "@/components/AddDriver.vue";
import AddLoader from "@/components/AddLoader";
import format from "date-fns/format";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      loading: false,
      message: "",
      resultMsg: "",
      items: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
      searchText: "",
      // new data start
      search: "",
      headers: [
        {
          text: "Driver Id",
          align: "start",
          sortable: false,
          value: "refId",
          divider: true,
          width: 80,
        },
        { text: "First Name", value: "firstName", divider: true, width: 150 },
        { text: "Last Name", value: "lastName", divider: true, width: 80 },
        {
          text: "Email",
          value: "email",
          divider: true,
          width: 100,
        },
        {
          text: "License Number",
          value: "licenseNumber",
          divider: true,
          width: 100,
        },
        {
          text: "License Expiry",
          value: "licenseExperyDate",
          divider: true,
          width: 120,
        },

        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          width: 100,
        },
      ],
      bottom: false,
    };
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("driver-added", (error) => {
      CustomLog.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("driver-update", (error) => {
      CustomLog.log(error);
      this.loadList(this.page, "get");
    });
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        CustomLog.log("Next Page Data");
      }
    },
  },
  methods: {
    rowClass(item) {
      var rowClass = "active-row";
      if (item.status.id == "2") {
        rowClass = "inactive-row";
      }
      return rowClass;
    },
    exportData() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "driver");
      axios
        .post(BASE_URL + "export.php", formData, {
          responseType: "arraybuffer",
        })
        .then(function (response) {
          vm.loading = false;
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `All-drivers-${new Date().toLocaleDateString()}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    addTableScrollListener() {
      document
        .querySelector(".v-data-table__wrapper")
        .addEventListener("scroll", () => {
          this.bottom = this.bottomVisible();
        });
    },
    bottomVisible() {
      const scrollY = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollTop;
      const tableHeight = document.querySelector(
        ".v-data-table__wrapper table"
      ).clientHeight;
      const visible = document.querySelector(
        ".v-data-table__wrapper "
      ).clientHeight;
      const pageHeight = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollHeight;
      if (tableHeight >= visible) {
        const bottomOfPage = visible + scrollY >= pageHeight;
        return bottomOfPage || pageHeight < visible;
      } else {
        return false;
      }
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(date, "Do MMM YYYY");
      }
    },
    loadList(page, action) {
      this.loading = true;
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      CustomLog.log(this.userInfo.fname);
      const config = {
        headers: {
          accept: "application/json",
        },
        data: {},
      };
      axios
        .post(BASE_URL + "driver.php?page=" + page, formData, config)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          //Add Scroll Listener
          if (vm.items.length <= 0) {
            vm.addTableScrollListener();
          } //end
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.totalItems = parseInt(response.data.totalItems);
            //vm.page = parseInt(response.data.page);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    getPageData(page) {
      CustomLog.log(page);
      this.loadList(page, this.action);
    },
    update(data) {
      CustomLog.log(data.id);
      EventBus.$emit("edit-driver", data);
    },
    searchQuery(page) {
      CustomLog.log(this.searchText.length);
      if (this.searchText.length > 2) {
        this.loading = true;
        const vm = this;
        var formData = new FormData();
        formData.append("keyword", vm.searchText);
        formData.append("adminId", vm.userInfo.id);
        formData.append("action", "search");
        axios
          .post(BASE_URL + "driver.php?page=" + page, formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            if (response.data.error) {
              vm.message = response.data.message;
              if (page == 1) {
                vm.resultMsg = "No result found";
                vm.items = [];
              }
            } else {
              vm.items = response.data.list;
            }
          })
          .catch(function (error) {
            vm.loading = false;

            CustomLog.log(error);
          });
      } else if (this.searchText.length == 0) {
        this.page = 1;
        this.loadList(this.page, this.action);
      }
    },
  },
  components: { AddDriver, AddLoader },
};
</script>
<style scoped>
tr.active-row {
  color: #222;
}
tr.inactive-row {
  color: #b1b1b1;
}
.search-field {
  padding-top: 0px !important;
  margin-top: 0px !important ;
  max-width: 300px;
}
</style>