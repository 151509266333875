<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        color="primary"
        small
        class="ml-3"
        outlined
        v-on="on"
        @click="showDialog"
        ><v-icon>mdi-cog</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <h2 class="title">{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="closeDialog()"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="scheduleForList"
                  v-model="scheduleFor"
                  item-text="title"
                  item-value="id"
                  label="Schedule For*"
                  :rules="rules.select"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Maintenance Type*"
                  required
                  v-model="maintenanceType"
                  :rules="rules.input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    <script>
import EventBus from "@/eventBus";
import axios from "axios";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      userInfo: null,
      title: "New Maintenance Type",
      btnLabel: "Add",
      id: 0,
      rules: {
        select: [(v) => !!v || "Item is required"],
        select2: [(v) => v.length > 0 || "Item is required in select 2"],
        input: [
          (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
        ],
      },
      loading: false,
      dialog: false,
      action: "add",
      scheduleForList: [
        { title: "Truck", id: 1 },
        { title: "Trailer", id: 2 },
      ],
      scheduleFor: { title: "Truck", id: 1 },

      maintenanceType: "",
    };
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        var formData = new FormData();
        formData.append("adminId", vm.userInfo.id);
        formData.append("for", vm.scheduleFor.id);
        formData.append("type", vm.maintenanceType);
        formData.append("action", vm.action);
        if (vm.action == "update") {
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "maintenanceType.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {
              message: vm.message,
              isError: response.data.error,
            };
            EventBus.$emit("display-message", messageObject);
            if (!response.data.error) {
              EventBus.$emit("maintenance-type-added", "maintenanceType");
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
            let messageObject = {'message':"Some error occured!","isError":true}
            EventBus.$emit("display-message", messageObject);
          });
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    showDialog() {
      this.btnLabel = "Add";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "New Maintenance Type";
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
  },
};
</script>
    
    <style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
    