<template>
  <v-container fill-height fluid grid-list-xl>
    <AddLoader :dialog="loading" />
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <v-card color="white" flat class="text-lg-center mx-auto" width="480">
          <h2 class="title pa-3">Profile</h2>
          <div class="pa-3 image-section">
            <v-avatar size="120" color="grey">
              <img :src="profileImage" alt="alt" v-if="profileImage != ''" />
              <span v-else class="white--text display-3 font-weight-bold">{{
                firstName.substring(0, 1)
              }}</span>
            </v-avatar>
            <p class="title mb-1">{{ firstName }} {{ lastName }}</p>
            <p class="subheading mt-1">{{ email }}</p>
            <ChangePassword />
          </div>
          <div class="pa-3">
            <v-form class="px-3" ref="form" enctype="multipart/form-data">
              <v-text-field
                label="First Name"
                v-model="firstName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Last Name"
                v-model="lastName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn
                text
                class="primary mx-0 mt-3"
                @click="submit()"
                :loading="loading"
                >Update</v-btn
              >
            </v-form>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ChangePassword from "../components/ChangePassword.vue";
import AddLoader from "@/components/AddLoader";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      message: "",
      profileImage: "",
      email: "",
      firstName: "",
      lastName: "",
      fileName: "",
      loading: false,
      userInfo: {},
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
    };
  },
  components: { ChangePassword, AddLoader },
  methods: {
    submit() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("firstName", vm.firstName);
      formData.append("lastName", vm.lastName);
      formData.append("adminId", vm.userInfo.id);
      formData.append("action", "updateProfile");
      axios
        .post(BASE_URL + "profile.php", formData)
        .then(function (response) {
          vm.loading = false;
          vm.message = response.data.message;
          CustomLog.log(response);
          let messageObject = {
            message: vm.message,
            isError: response.data.error,
          };
          EventBus.$emit("display-message", messageObject);
          if (response.data.error) {
            CustomLog.log(vm.message);
          } else {
            EventBus.$emit("profileUpdated", response.data.auth);
            vm.$store.dispatch("saveloggedinInfo", response.data.auth);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },

    pickFile() {
      this.$refs.profileImg.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      CustomLog.log(files[0].name);
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        this.profileImage = URL.createObjectURL(files[0]);
        this.changeProfileImage();
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.firstName = this.userInfo.fname;
    this.lastName = this.userInfo.lname;
    this.email = this.userInfo.email;
  },
};
</script>
<style>
.image-section {
  background-color: #f8f9fa;
}
.edit-profile-img {
  margin-top: -20px;
}
</style>
