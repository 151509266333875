<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-col cols="12" md="6" sm="12" lg="4" xl="3">
        <v-card class="form-card">
          <v-card-title class="grey darken-3 white--text"
            >Welcome to</v-card-title
          >
          <v-card-subtitle class="caption grey darken-3 white--text"
            >Maintainance login page</v-card-subtitle
          >
          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form" v-if="!verifyError">
                <v-text-field
                  label="Enter your email"
                  v-model="username"
                  v-on:keyup.enter="focusOnPassword"
                  :rules="usernameRules"
                  required
                ></v-text-field>
                <v-text-field
                  label="Enter your password"
                  v-model="password"
                  ref="password"
                  min="8"
                  :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  counter
                  v-on:keyup.enter="submit"
                  required
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-btn
                    @click="submit"
                    :class="{
                      'primary darken-2 white--text': valid,
                      disabled: !valid,
                    }"
                    :loading="loading"
                    >Login</v-btn
                  >
                  <span class="my-2 red--text">{{ message }}</span>
                </v-layout>
              </v-form>

              <!-- verification form -->
              <v-form v-model="valid" v-if="verifyError" ref="form">
                <v-text-field
                  label="Enter OTP"
                  v-model="otp"
                  :rules="usernameRules"
                  v-on:keyup.enter="verifyCode"
                  required
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-btn
                    @click="verifyCode"
                    :class="{
                      'primary darken-2 white--text': valid,
                      disabled: !valid,
                    }"
                    :loading="loading"
                    >Verify</v-btn
                  >
                  <span class="my-2 red--text">{{ message }}</span>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      password: "",
      username: "",
      message: "",
      valid: false,
      usernameRules: [(v) => v.length >= 5 || "Minimum length is 5 characters"],
      passwordRules: [(v) => v.length >= 5 || "Minimum length is 5 characters"],
      loading: false,
      e1: true,
      authenticated: false,
      timezoneOffset: 0,
      redirectTo: "",
      verifyError: false,
      otp: "",
    };
  },
  methods: {
    focusOnPassword() {
      this.$refs.password.focus();
    },
    submit() {
      const vm = this;
      vm.loading = true;

      var formData = new FormData();
      formData.append("email", vm.username);
      formData.append("password", vm.password);
      formData.append("timezone_offset", vm.timezoneOffset);
      axios
        .post(BASE_URL + "login.php", formData)
        .then(function (response) {
          vm.loading = false;
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            if (response.data.verifyError) {
              vm.verifyError = response.data.verifyError;
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    verifyCode() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("email", vm.username);
      formData.append("otp", vm.otp);
      formData.append("action", "verification");
      axios
        .post(BASE_URL + "login.php", formData)
        .then(function (response) {
          vm.loading = false;
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.$store.dispatch("saveloggedinInfo", response.data.auth);
            EventBus.$emit("authenticated", true);
            vm.$router.replace({ name: "report" }).catch((err) => {
              CustomLog.log(err);
            });
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
  mounted() {
    if (this.$store.state.loggedinInfo != null) {
      if (this.$store.state.loggedinInfo.id > 0) {
        this.authenticated = true;
      } else {
        this.authenticated = false;
      }
    }
  },
  created() {
    this.redirectTo = this.$route.query.redirect;
    var timezone_offset_minutes = new Date().getTimezoneOffset();
    this.timezoneOffset =
      timezone_offset_minutes === 0 ? 0 : -timezone_offset_minutes;
  },
};
</script>
<style scoped>
.form-card {
  min-width: 400px;
}
</style>