<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <v-card>
      <v-card-title>
        <h2 class="title">{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-menu
                  ref="newDateMenu"
                  v-model="newDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="newDate"
                      :label="newDateLabel"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newDate"
                    no-title
                    scrollable
                    @input="$refs.newDateMenu.save(newDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="newDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.newDateMenu.save(newDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-textarea
                  label="Remarks*"
                  v-model="remarks"
                  required
                  :rules="inputRules"
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click.stop="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  <script>
import EventBus from "@/eventBus";
import axios from "axios";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      remarks: "",
      itemId: 0,
      maintenanceStatus: "",
      userInfo: null,
      title: "Update Asset / Driver",
      btnLabel: "Update",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      dayRules: [(v) => /^[0-9]+$/.test(v) || "Enter valid integer value"],
      loading: false,
      dialog: false,
      action: "add",
      item: {},
      parentItem: {},
      newDate: "",
      newDateMenu: false,
      newDateLabel: "",
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const vm = this;
        var formData = new FormData();
        formData.append("ran", Math.random() * 10);
        formData.append("adminId", this.userInfo.id);
        formData.append("action", "add");
        formData.append("name", this.item.title);
        formData.append("oldDate", this.item.date);
        formData.append("newDate", this.newDate);
        formData.append("remarks", this.remarks);
        formData.append("type", this.parentItem.typeId);
        formData.append("itemId", this.parentItem.itemId);
        CustomLog.log(this.userInfo.fname);
        axios
          .post(BASE_URL + "reportHistory.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {
              message: vm.message,
              isError: response.data.error,
            };
            EventBus.$emit("display-message", messageObject);
            vm.error = response.data.error;
            if (!vm.error) {
              EventBus.$emit("add-report-history", vm.error);
              vm.$refs.form.reset();
            }
            vm.dialog = false;
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.loading = false;
      this.btnLabel = "Update";
      this.name = "";
      this.dialog = true;
      this.action = "add";
      this.title = "Update Asset / Driver";
      this.remarks = "";
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("update-report-item", (data) => {
      this.item = data.subItem;
      this.parentItem = data.parentItem;
      this.newDate = this.item.date;
      this.newDateLabel = this.item.title + " Date*";
      this.id = data.parentItem.itemId;
      CustomLog.log(this.item);
      this.showDialog();
      this.title =
        "Update " + this.parentItem.type + " - " + this.parentItem.refId;
    });
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
  },
};
</script>
  
  <style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
  