var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddLoader',{attrs:{"dialog":_vm.loading}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.items.length == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12 py-3"}},[_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.resultMsg))])])],1):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Maintenance "),_c('AddMaintenance'),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.reloadAll()}}},[_vm._v("Show All")]),_c('v-menu',{ref:"dateFilterMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.dateFilter,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dateFilter=$event},"update:return-value":function($event){_vm.dateFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ml-3 mr-4 max-width",attrs:{"label":"Date Filter","dense":"","clearable":"","append-icon":"mdi-calendar-check-outline","readonly":""},on:{"click:clear":function($event){return _vm.reloadAll()},"click:append":function($event){_vm.dateFilterMenu = true}},model:{value:(_vm.dateFilterTextField),callback:function ($$v) {_vm.dateFilterTextField=$$v},expression:"dateFilterTextField"}},on))]}}]),model:{value:(_vm.dateFilterMenu),callback:function ($$v) {_vm.dateFilterMenu=$$v},expression:"dateFilterMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","multiple":"","range":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateFilterMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.filterByDate()}}},[_vm._v("OK")])],1)],1),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.exportData()}}},[_vm._v("Export "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('AddMaintenanceType')],1),_c('v-data-table',{staticClass:"maintenance-table",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":"74vh","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"current-items":_vm.currentItems},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(item))]),_c('v-btn',{attrs:{"color":"primary","small":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.update(item)}}},[_vm._v("Update")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.detail))])]}}])})],1),_c('div',{staticClass:"text-right my-3 mr-5"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Grand Total: "+_vm._s(_vm.grandTotal))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }