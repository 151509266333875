var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('AddReportRemarks'),_c('AddLoader',{attrs:{"dialog":_vm.loading}}),(_vm.items.length == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12 py-3"}},[_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.resultMsg))])])],1):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Upcoming 30 Days Report "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.reloadAll()}}},[_vm._v("Show All")]),_c('v-menu',{ref:"dateFilterMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.dateFilter,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dateFilter=$event},"update:return-value":function($event){_vm.dateFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ml-3 mr-4 max-width",attrs:{"label":"Date Filter","dense":"","clearable":"","append-icon":"mdi-calendar-check-outline","readonly":""},on:{"click:clear":function($event){return _vm.reloadAll()},"click:append":function($event){_vm.dateFilterMenu = true}},model:{value:(_vm.dateFilterTextField),callback:function ($$v) {_vm.dateFilterTextField=$$v},expression:"dateFilterTextField"}},on))]}}]),model:{value:(_vm.dateFilterMenu),callback:function ($$v) {_vm.dateFilterMenu=$$v},expression:"dateFilterMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","multiple":"","range":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateFilterMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.filterByDate()}}},[_vm._v("OK")])],1)],1),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":"78vh","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.expiryItemsLabel",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" "+_vm._s(item.expiryItemsLabel)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-container',_vm._l((item.expiryItems),function(expiryItem,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Item")]),_c('div',[_vm._v(_vm._s(expiryItem.title))])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Expire Date")]),_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getFormatedDate(expiryItem.date))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","small":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.update(expiryItem, item)}}},[_vm._v("Update")])],1)],1)}),1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }