import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from "vuetify/es5/services/goto";
import Login from '../views/Login.vue'
import Maintenance from '../views/Maintenance.vue'
import Report from '../views/Report.vue'
import Truck from '../views/Truck.vue'
import Trailer from '../views/Trailer.vue'
import Driver from '../views/Driver.vue'
import Profile from '../views/Profile.vue'
import ReportHistory from '../views/ReportHistory.vue'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  }, {
    path: '/report',
    name: 'report',
    component: Report,
    meta: { requiresAuth: true }
  }, {
    path: '/report-history',
    name: 'report-history',
    component: ReportHistory,
    meta: { requiresAuth: true }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/truck',
    name: 'truck',
    component: Truck,
    meta: { requiresAuth: true }
  },
  {
    path: '/trailer',
    name: 'trailer',
    component: Trailer,
    meta: { requiresAuth: true }
  },
  {
    path: '/driver',
    name: 'driver',
    component: Driver,
    meta: { requiresAuth: true }
  }
]



const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return goTo(to.hash);
  } else if (savedPosition) {
    return goTo(savedPosition.y);
  } else {
    // check for mobileTo meta property
    to.matched.some((record) => {
      if (record.meta.mobileTo) {
        // scroll to that location if screen size is mobile
        if (document.documentElement.clientWidth <= 600) {
          return goTo(record.meta.mobileTo);
        }
      }
    });
  }
  // default to top of page
  // or return false if you don't want any behavior
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})
router.beforeEach((to, from, next) => {
  if (to.name != from.name) {
    if (
      store.state.loggedinInfo ||
      to.name == "login"
    ) {
      next();
    } else {
      //  next({ replace: true, name: "clientLogin" });
      next();

    }

  }
});

export default router;
