<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        color="primary"
        small
        class="ml-3"
        outlined
        v-on="on"
        @click="showDialog"
        >Add New Truck</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <h2 class="title">
          {{ title }}
        </h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Truck Id*"
                  v-model="truckId"
                  :rules="inputRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="VIN*"
                  v-model="vin"
                  required
                  :rules="inputRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="fuelList"
                  v-model="fuelType"
                  item-text="title"
                  item-value="id"
                  label="Fuel Type*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Issue State/Province"
                  v-model="issueProvince"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  label="License Plate Number"
                  v-model="plateNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Year" v-model="year"></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Make" v-model="make"></v-text-field>
              </v-col>

              <v-col cols="12" md="4" class="py-0">
                <v-text-field label="Model" v-model="model"></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-select
                  :items="companyList"
                  v-model="company"
                  item-text="title"
                  item-value="id"
                  label="Under Taking Company*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="anualSaftyDateMenu"
                  v-model="anualSaftyDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="anualSaftyDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="anualSaftyDate"
                      label="Annual Safety*"
                      readonly
                      required
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="anualSaftyDate"
                    no-title
                    scrollable
                    @input="$refs.anualSaftyDateMenu.save(anualSaftyDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="anualSaftyDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.anualSaftyDateMenu.save(anualSaftyDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- PM -->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="pmDateMenu"
                  v-model="pmDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="pmDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="pmDate"
                      label="PM*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pmDate"
                    no-title
                    scrollable
                    @input="$refs.pmDateMenu.save(pmDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="pmDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.pmDateMenu.save(pmDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--PM END-->
              <!-- Transponder -->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="transponderDateMenu"
                  v-model="transponderDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="transponderDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="transponderDate"
                      label="Transponder*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="transponderDate"
                    no-title
                    scrollable
                    @input="$refs.transponderDateMenu.save(transponderDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="transponderDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.transponderDateMenu.save(transponderDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--Transponder end-->
              <!--CVOR-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="cvorDateMenu"
                  v-model="cvorDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="cvorDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="cvorDate"
                      label="CVOR*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="cvorDate"
                    no-title
                    scrollable
                    @input="$refs.cvorDateMenu.save(cvorDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cvorDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.cvorDateMenu.save(cvorDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--CVOR END-->
              <!--IFTA-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="iftaDateMenu"
                  v-model="iftaDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="iftaDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="iftaDate"
                      label="IFTA*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="iftaDate"
                    no-title
                    scrollable
                    @input="$refs.iftaDateMenu.save(iftaDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="iftaDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.iftaDateMenu.save(iftaDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--IFTA END-->
              <!-- Kentucky -->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="kentuckyDateMenu"
                  v-model="kentuckyDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="kentuckyDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="kentuckyDate"
                      label="Kentucky*"
                      readonly
                      required
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="kentuckyDate"
                    no-title
                    scrollable
                    @input="$refs.kentuckyDateMenu.save(kentuckyDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="kentuckyDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.kentuckyDateMenu.save(kentuckyDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Kentucky end-->
              <!-- Lease expire -->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="leaseExpireDateMenu"
                  v-model="leaseExpireDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="leaseExpireDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="leaseExpireDate"
                      label="Lease Expire*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="leaseExpireDate"
                    no-title
                    scrollable
                    @input="$refs.leaseExpireDateMenu.save(leaseExpireDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="leaseExpireDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.leaseExpireDateMenu.save(leaseExpireDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Lease expire end-->
              <!--MCS90-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="mcs90DateMenu"
                  v-model="mcs90DateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="mcs90Date"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="mcs90Date"
                      label="MSC90*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mcs90Date"
                    no-title
                    scrollable
                    @input="$refs.mcs90DateMenu.save(mcs90Date)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mcs90DateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.mcs90DateMenu.save(mcs90Date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- MCS90 end-->
              <!-- PINK SLIP-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="pinkSlipDateMenu"
                  v-model="pinkSlipDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="pinkSlipDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="pinkSlipDate"
                      label="Pink Slip*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="pinkSlipDate"
                    no-title
                    scrollable
                    @input="$refs.pinkSlipDateMenu.save(pinkSlipDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="pinkSlipDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.pinkSlipDateMenu.save(pinkSlipDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- PINK SLIP end-->
              <!--NEW MEXICO PERMIT-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="newMexicoPermitDateMenu"
                  v-model="newMexicoPermitDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newMexicoPermitDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="newMexicoPermitDate"
                      label="New Mexico Permit*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newMexicoPermitDate"
                    no-title
                    scrollable
                    @input="
                      $refs.newMexicoPermitDateMenu.save(newMexicoPermitDate)
                    "
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="newMexicoPermitDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.newMexicoPermitDateMenu.save(newMexicoPermitDate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--NEW MEXICO PERMIT end-->
              <!--NEW YORK  PERMIT-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="newYorkPermitDateMenu"
                  v-model="newYorkPermitDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newYorkPermitDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="newYorkPermitDate"
                      label="New York Permit*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newYorkPermitDate"
                    no-title
                    scrollable
                    @input="$refs.newYorkPermitDateMenu.save(newYorkPermitDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="newYorkPermitDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.newYorkPermitDateMenu.save(newYorkPermitDate)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--NEW YORK  PERMIT end-->
              <!--ORIGIN PERMIT-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="originPermitDateMenu"
                  v-model="originPermitDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="originPermitDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="originPermitDate"
                      label="Origin Permit*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="originPermitDate"
                    no-title
                    scrollable
                    @input="$refs.originPermitDateMenu.save(originPermitDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="originPermitDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.originPermitDateMenu.save(originPermitDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--ORIGIN PERMIT end-->
              <!--UCR-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="ucrDateMenu"
                  v-model="ucrDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="ucrDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="ucrDate"
                      label="UCR*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ucrDate"
                    no-title
                    scrollable
                    @input="$refs.ucrDateMenu.save(ucrDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="ucrDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.ucrDateMenu.save(ucrDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--UCR end-->
              <!--NMFTA -->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="nmftaDateMenu"
                  v-model="nmftaDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="nmftaDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="nmftaDate"
                      label="NMFTA*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="nmftaDate"
                    no-title
                    scrollable
                    @input="$refs.nmftaDateMenu.save(nmftaDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="nmftaDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.nmftaDateMenu.save(nmftaDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- NMFTA end-->
              <!--Vehicle Cab Card-->
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="cabCardDateMenu"
                  v-model="cabCardDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="cabCardDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="cabCardDate"
                      label="Vehicle Cab Card*"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="cabCardDate"
                    no-title
                    scrollable
                    @input="$refs.cabCardDateMenu.save(cabCardDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cabCardDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.cabCardDateMenu.save(cabCardDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!--Vehicle Cab Card end-->
              <!--MC Certificate-->
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  :items="mcCertificateList"
                  v-model="mcCertificate"
                  item-text="title"
                  item-value="id"
                  label="MC Certificate*"
                  return-object
                ></v-select>
              </v-col>
              <!--MC Certificate end-->
              <!--NMFTA SCAC-->
              <v-col cols="12" md="12" class="py-0">
                <v-select
                  :items="nmftaList"
                  v-model="nmfta"
                  item-text="title"
                  item-value="id"
                  label="NMFTA SCAC*"
                  return-object
                ></v-select>
              </v-col>
              <!--NMFTA SCAC end-->
              <v-col cols="12" md="12" class="py-0" v-if="action == 'update'">
                <v-select
                  :items="statusList"
                  v-model="status"
                  item-text="title"
                  item-value="id"
                  label="Status*"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >
                <v-btn
                  class="ml-3 mx-0 mt-3"
                  color="error"
                  outlined
                  v-show="isVisibleDeleteBtn"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteItem()"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
import { format } from "date-fns";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      isVisibleDeleteBtn: false,
      truckId: "",
      vin: "",
      issueProvince: "",
      plateNumber: "",
      year: "",
      make: "",
      model: "",
      userInfo: null,
      title: "Add New Truck",
      btnLabel: "Add Truck",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
      fuelList: [],
      fuelType: {},
      companyList: [
        { id: "1", title: "Marianas Transportation" },
        { id: "2", title: "Marianas Logistics" },
      ],
      company: { id: "1", title: "Marianas Transportation" },
      mcCertificateList: [
        { id: "1", title: "Yes" },
        { id: "2", title: "No" },
      ],
      mcCertificate: { id: "1", title: "Yes" },
      nmftaList: [
        { id: "1", title: "Yes" },
        { id: "2", title: "No" },
      ],
      nmfta: { id: "1", title: "Yes" },
      anualSaftyDate: "",
      anualSaftyDateMenu: false,
      pmDate: "",
      pmDateMenu: false,
      transponderDate: "",
      transponderDateMenu: false,
      cvorDate: "",
      cvorDateMenu: false,
      iftaDate: "",
      iftaDateMenu: false,
      kentuckyDate: "",
      kentuckyDateMenu: false,
      leaseExpireDate: "",
      leaseExpireDateMenu: false,
      mcs90Date: "",
      mcs90DateMenu: false,
      pinkSlipDate: "",
      pinkSlipDateMenu: false,
      newMexicoPermitDate: "",
      newMexicoPermitDateMenu: false,
      newYorkPermitDate: "",
      newYorkPermitDateMenu: false,
      originPermitDate: "",
      originPermitDateMenu: false,
      ucrDate: "",
      ucrDateMenu: false,
      nmftaDate: "",
      nmftaDateMenu: false,
      cabCardDate: "",
      cabCardDateMenu: false,
      statusList: [
        { id: "1", title: "Enable" },
        { id: "2", title: "Disable" },
      ],
      status: { id: "1", title: "Enable" },
    };
  },
  methods: {
    getFormatedDate(date) {
      if (date == "") {
        return date;
      } else {
        return format(new Date(date), "yyyy-MM-dd");
      }
    },
    deleteItem() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("id", this.id);
      formData.append("action", "delete");
      axios
        .post(BASE_URL + "truck.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            EventBus.$emit("truck-update", vm.error);
            vm.dialog = false;
            vm.$refs.form.reset();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        var formData = new FormData();
        formData.append("adminId", vm.userInfo.id);
        formData.append("truckId", vm.truckId);
        formData.append("vin", vm.vin);
        formData.append("issueProvince", vm.issueProvince);
        formData.append("plateNumber", vm.plateNumber);
        formData.append("year", vm.year);
        formData.append("make", vm.make);
        formData.append("model", vm.model);
        formData.append("fuelId", vm.fuelType.id);
        formData.append("companyId", vm.company.id);
        formData.append("mcCertificate", vm.mcCertificate.id);
        formData.append("nmftaScac", vm.nmfta.id);
        formData.append("annualSafetyDate", vm.anualSaftyDate);
        formData.append("pmDate", vm.pmDate);
        formData.append("transponderDate", vm.transponderDate);
        formData.append("cvorDate", vm.cvorDate);
        formData.append("iftaDate", vm.iftaDate);
        formData.append("kentuckyDate", vm.kentuckyDate);
        formData.append("leaseExpireDate", vm.leaseExpireDate);
        formData.append("mcs90Date", vm.mcs90Date);
        formData.append("pinkDlipDate", vm.pinkSlipDate);
        formData.append("newMaxicoPermitDate", vm.newMexicoPermitDate);
        formData.append("newYorkPermitDate", vm.newYorkPermitDate);
        formData.append("originPermitDate", vm.originPermitDate);
        formData.append("ucrDate", vm.ucrDate);
        formData.append("nmftaDate", vm.nmftaDate);
        formData.append("cabCardDate", vm.cabCardDate);
        formData.append("action", vm.action);
        if (vm.action == "update") {
          formData.append("id", vm.id);
          formData.append("status", vm.status.id);
        }
        axios
          .post(BASE_URL + "truck.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {
              message: vm.message,
              isError: response.data.error,
            };
            EventBus.$emit("display-message", messageObject);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("truck-update", vm.error);
              } else {
                EventBus.$emit("truck-added", vm.error);
              }
              vm.dialog = false;
              vm.$refs.form.reset();
            }
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
            let messageObject = {
              message: "Some error occured!",
              isError: true,
            };
            EventBus.$emit("display-message", messageObject);
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.btnLabel = "Add Truck";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Truck";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      CustomLog.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadDefaultData(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      axios
        .post(BASE_URL + "setting.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.fuelList = response.data.list;
            if (vm.fuelList.length > 0) {
              vm.fuelType = response.data.list[0];
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    showDeleteBtn(e) {
      if (e.altKey && e.shiftKey && e.code === "KeyD") {
        if (this.action == "update") {
          this.isVisibleDeleteBtn = true;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-truck", (data) => {
      CustomLog.log(data.mcCertificate);
      this.truckId = data.refId;
      this.plateNumber = data.plateNumber;
      this.vin = data.vin;
      this.year = data.year;
      this.make = data.make;
      this.model = data.model;
      this.issueProvince = data.issueProvince;
      this.fuelType = data.fuelType;
      this.company = data.company;
      this.mcCertificate = data.mcCertificate;
      this.nmfta = data.nmftaScac;
      this.anualSaftyDate = this.getFormatedDate(data.annualSafetyDate);
      this.pmDate = this.getFormatedDate(data.pmDate);
      this.transponderDate = this.getFormatedDate(data.transponderDate);
      this.cvorDate = this.getFormatedDate(data.cvorDate);
      this.iftaDate = this.getFormatedDate(data.iftaDate);
      this.kentuckyDate = this.getFormatedDate(data.kentuckyDate);
      this.leaseExpireDate = this.getFormatedDate(data.leaseExpireDate);
      this.mcs90Date = this.getFormatedDate(data.mcs90Date);
      this.pinkSlipDate = this.getFormatedDate(data.pinkSlipDate);
      this.newMexicoPermitDate = this.getFormatedDate(data.newMaxicoPermitDate);
      this.newYorkPermitDate = this.getFormatedDate(data.newYorkPermitDate);
      this.originPermitDate = this.getFormatedDate(data.originPermitDate);
      this.ucrDate = this.getFormatedDate(data.ucrDate);
      this.nmftaDate = this.getFormatedDate(data.nmftaDate);
      this.cabCardDate = this.getFormatedDate(data.cabCardDate);
      this.id = data.id;
      this.btnLabel = "Update Truck";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Truck";
      this.status = data.status;
      this.isVisibleDeleteBtn = false;
    });
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.loadDefaultData("get");
    window.addEventListener("keydown", this.showDeleteBtn);
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
