import Vue from 'vue';
const ENV = process.env.NODE_ENV;
export default class CustomLog extends Vue {
    static log(message) {
        
        if (ENV.trim() == 'production') {
            console.log = function () {

            };
        }else {
            console.log(message);
        }

    }
}