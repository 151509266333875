var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddLoader',{attrs:{"dialog":_vm.loading}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Manage Trailers "),_c('AddTrailer'),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.exportData()}}},[_vm._v("Export "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"disable-pagination":"","fixed-header":"","hide-default-footer":"","item-class":_vm.rowClass,"height":"80vh"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.update(item)}}},[_vm._v("Update")])]}},{key:"item.fuelType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fuelType.title)+" ")]}},{key:"item.mcCertificate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mcCertificate.title)+" ")]}},{key:"item.nmftaScac",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nmftaScac.title)+" ")]}},{key:"item.annualSafetyDateExtended",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("" + (item.highlightAnnualSafetyDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.annualSafetyDateExtended)+" ")])]}},{key:"item.pmDateExtended",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightPmDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.pmDateExtended)+" ")])]}},{key:"item.insuranceSlipDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightInsuranceSlipDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.insuranceSlipDate)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }