import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);



export default new Vuex.Store({
  state: {
    loggedinInfo: null,
    dateTime: null,
  },
  mutations: {
    saveloggedinInfo(state, loggedUser) {
      state.loggedinInfo = loggedUser;
      if (loggedUser == null) {
        state.dateTime = null;
      } else {
        state.dateTime = new Date();
      }
    },
  },
  actions: {
    saveloggedinInfo(context, loggedUser) {
      context.commit("saveloggedinInfo", loggedUser);
    },
  },
  modules: {},
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
